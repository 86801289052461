import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InterStyled from "../../styles/InterStyled";
import StepperButtons from "./StepperButtons";
import { ProductType } from "../../types";
import { AnswerType } from "../ReturnQuestions";
import { AddressFormData } from "./AddressForm";
import { ShopifyProduct } from "../../utils/helper/LineItemTypes";

import dayjs from "dayjs";
import "dayjs/locale/de";
import Button from "../../styles/Button";
import { Progress } from "../LoadingCircular";

export default function DoneScreen(props: {
  onContinuePressed: () => Promise<boolean>;
  onBackPressed?: () => void;
  product: ProductType | undefined;
  date_of_purchase: Date | undefined;
  answers: AnswerType[] | undefined;
  exchange_wishes: (ShopifyProduct | undefined)[] | undefined;
  address: AddressFormData;
  location: string;
  email: string | undefined;
}) {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);

  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    setLoading(true);
    props.onContinuePressed().then((value) => {
      if (value === false) {
        setLoading(false);
      }
    });
  };

  function capitalize(str) {
    if (!str) return str; // Handle empty string case
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <InterStyled interStyle="Label 1">{t("Summary")}</InterStyled>
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1">
        {t("You bought a") +
          " " +
          (props.product?.title ?? "") +
          " " +
          t("at") +
          " " +
          props.location
            .split("_")
            .map((x) => capitalize(x))
            .join(" ") +
          " " +
          t("at_time") +
          " " +
          dayjs(props.date_of_purchase).format("LL") +
          " " + t("gekauft") +
          "."}
      </InterStyled>
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1">{t("Your data is the following:")}</InterStyled>
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("email")}</b>
        <span>{props.email}</span>
      </InterStyled>
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Name")}</b>
        <span>{props.address.name}</span>
      </InterStyled>
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Address1")}</b>
        <span>{props.address.address1}</span>
      </InterStyled>
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Address2")}</b>
        <span>{props.address.address2}</span>
      </InterStyled>
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>
          {t("ZIP")} {t("City")}
        </b>
        <span>
          {props.address.zip} {props.address.city}
        </span>
      </InterStyled>
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Country")}</b>
        <span>{props.address.country}</span>
      </InterStyled>
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Receipt upload")}</b>
        <span>OK</span>
      </InterStyled>
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Return reason")}</b>
      </InterStyled>
      {props.answers?.map((x) => {
        return (
          <InterStyled key={x.id} interStyle="P1" style={{ display: "flex", gap: "7px" }}>
            {x.text}
          </InterStyled>
        );
      })}
      <div style={{ height: 10, clear: "both" }} />
      <InterStyled interStyle="P1" style={{ display: "flex", gap: "7px" }}>
        <b>{t("Exchange Wish")}</b>
      </InterStyled>
      {props.exchange_wishes?.map((x) => {
        return (
          <InterStyled key={x?.id} interStyle="P1" style={{ display: "flex", gap: "7px" }}>
            {x?.title ?? ""}
          </InterStyled>
        );
      })}
      <div style={{ height: 30, clear: "both" }} />
      <Button color="secondary" fullWidth onClick={handleClick} variant="contained" disabled={loading}>
        {loading ? <Progress /> : t("Finish and get return label")}
      </Button>
      <div style={{ height: 30, clear: "both" }} />
      <StepperButtons
        onContinuePressed={undefined}
        onBackPressed={loading ? undefined : props.onBackPressed}
        labels={["Go Back", "Finish"]}
      />
    </div>
  );
}
