import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InterStyled from '../../styles/InterStyled';
import StepperButtons from './StepperButtons';
import UploadForm from '../UploadForm';
import { Alert } from '@material-ui/lab';

export type UrlAnswerType = { urls: string[]; text: string };

export default function ReceiptUpload(props: {
  onContinuePressed: () => void;
  onBackPressed: () => void;
  shopName: string | undefined;
  value: UrlAnswerType;
  setValue: (x: UrlAnswerType) => void;
}) {
  const { t } = useTranslation();
  const [fin, setFin] = useState<UrlAnswerType | undefined>(props.value);

  const handleUpload = (answers: string[] | UrlAnswerType) => {
    let ret_val: UrlAnswerType = { urls: [], text: '' };
    if (Array.isArray(answers)) {
      if (typeof ret_val === 'string') {
        ret_val = {
          urls: answers,
          text: '',
        };
      }
      props.setValue(ret_val as UrlAnswerType);
      setFin(ret_val as UrlAnswerType);
    } else {
      props.setValue(answers as UrlAnswerType);
      setFin(answers as UrlAnswerType);
    }
  };

  useEffect(() => {
    if (props.value !== undefined && props.value.urls[0] !== '') {
      setFin(props.value);
    }
  }, [props.value]);

  return (
    <div>
      <InterStyled interStyle='Label 1'>
        {props.value.urls.length > 0
          ? t('Upload a new receipt') + '.'
          : t('Upload Receipt')}
      </InterStyled>
      <div style={{ height: 20 }} />

      <InterStyled interStyle='P1'>
        {t('Please make sure we can read the receipt') +
          '. ' +
          t('The toothbrush you bought and the price need to be visible') +
          '.'}
      </InterStyled>

      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Alert severity='info'>{t('Please upload the receipt as jpg')}</Alert>
      </div>

      <div style={{ height: 20 }} />
      <UploadForm
        shop={props.shopName ?? ''}
        id={Date.now().toString() + '_receipt'}
        endpoint={'typeform_file_upload_detect'}
        answerQuestion={handleUpload}
        allowMultiple={false}
        autoContinue={true}
      />
      <StepperButtons
        onContinuePressed={
          fin !== undefined && fin.urls[0] !== ''
            ? props.onContinuePressed
            : undefined
        }
        onBackPressed={props.onBackPressed}
      />
    </div>
  );
}
