import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { XMLNode } from "../types";
import xml from "../utils/helper/XMLReader";
import Button from "../styles/Button";
import {
  FormControl,
  TextField as MuiInput,
  Container as ContainerMUI,
  Link,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { default as Lato } from "../styles/lato";
import getToken from "../utils/helper/getToken";
import KeepoalaBanner from "../assets/img/Keepoala_business_card_1024.png";
import Colors from "../utils/helper/Colors";
import { analytics } from "../firebase";
import ourusers from "../utils/helper/ourusers";
import { AuthContext } from "../utils/providers/AuthProvider";
import StyledRating, { IconContainer } from "./StyledRating";
import InterText from "../styles/Inter";
import { isMobile } from "react-device-detect";
import ProgressQuestionsLinear from "./ProgressQuestionsLinear";
import UploadForm from "./UploadForm";
import { Alert as MuiAlert } from "../styles/MUIOverwrite";
import { ArrowBackIconBack } from "./ReturnExchange";
declare module "react" {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  interface HTMLAttributes<T> {
    readonly indent?: number;
  }
}

const Alert = styled(MuiAlert)(spacing);

const Input = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.keepoala.dark,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.keepoala.dark,
    },
  },
})(MuiInput);

const LatoText = styled(Lato)(spacing);
const Container = styled(ContainerMUI)(spacing);
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "10vh",
    marginBottom: "5vh",
  },
  questions: {
    paddingBottom: "3em",
  },
  twoButtons: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    columnGap: "10px",
    width: "90%",
  },
  logo: {
    marginBottom: "16px",
    maxWidth: "60%",
  },
  root: {},
  error: {
    color: "red !important",
  },
  ".MuiRating-root": {
    width: "100%",
    marginBottom: "20px",
  },
}));

const Liindent = styled.li`
  margin-left: ${(props) => {
    switch (props.indent) {
      case 0:
        return 0;
      case 1:
        return "1em";
      case 2:
        return "2em";
      case 3:
        return "3em";
      case 4:
        return "4em";
      default:
        return "5em";
    }
  }};
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const Img = styled.img`
  max-width: 100%;
`;
export type AnswerType = { text: string; id: string; question?: string };

export default function ReturnQuestions(props: {
  category: string | undefined;
  category_fallback?: string;
  productName: string;
  productVariantID: string;
  orderName: string;
  userID?: string;
  orderID?: string | number;
  orderIDDoc?: string;
  successHandler: (answers: AnswerType[]) => void | undefined;
  nextExists?: boolean | undefined;
  shop?: string | undefined;
  skipStart?: boolean | undefined;
  skipEnd?: boolean | undefined;
  startBackHandler?: () => void;
  shopId?: string;
  default_answers?: AnswerType[];
}) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);

  const [isOnfocus, setIsOnfocus] = useState(false);

  var internal_props: {
    category?: string;
    category_fallback?: string;
    productName?: string;
    productVariantID?: string;
    orderID?: string;
    orderIDDoc?: string;
    orderName?: string;
    userID?: string;
    lang?: string;
    handler?: string;
    shop?: string;
  } = {};
  internal_props.shop = props.shop;
  // Category is set from the firestore_get_nps request and is sent as props from the parent component
  internal_props.category = props.category;
  internal_props.category_fallback = props.category_fallback;
  internal_props.orderName = props.orderName;
  internal_props.orderID = props.orderID?.toString();
  internal_props.orderIDDoc = props.orderIDDoc;
  internal_props.userID = props.userID;
  internal_props.productName = props.productName;
  internal_props.productVariantID = props.productVariantID !== undefined ? props.productVariantID : "unknown";

  // contains the question trees

  const levelstrings = [t("Why did you return"), t("Can you tell us more"), t("Why in detail")];

  // Tree of all questions + following questions
  const [tree, setTree]: [XMLNode[], (e: any) => void] = useState([]);
  const [oldTree, setOldTree]: [any[], (e: any) => void] = useState([]);

  // manage progressbar
  const [hide_progress, setHideProgress]: [boolean, any] = useState(false);

  const [currentQuestions, setCurrentQuestions]: [
    {
      text: string;
      id: string;
      text_question?: string;
      text_detail?: string;
      text_expand?: string;
      image?: string;
      text_submit?: string;
      level_string?: string;
      required?: boolean;
      regex?: string;
    }[],
    (e: any) => void
  ] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentMaxQuestions, setCurrentMaxQuestions] = useState(0);
  const [answers, setAnswers]: [AnswerType[], (e: any) => void] = useState([]);
  const [end, setEnd] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const [default_handled, setDefaulthandled]: [boolean, any] = useState(false);

  const [freeForm, setFreeForm] = useState("");
  const [freeFormError, setFreeFormError] = useState(undefined);
  const [ratingValue, setRatingValue]: [undefined | number, any] = useState(undefined);

  const [helpExpand, setHelpExpand]: [boolean, any] = useState(false);

  async function getData(lang: string) {
    await xml(lang).then((res) => {
      var xmlVal = res.children[0].children;
      if (res.children[0].children.length > 0) {
        var only_tree = xmlVal.filter((key: XMLNode) => key.attributes.cat === internal_props.category);
        console.log(internal_props.category);
        console.log(only_tree.length);
        if (only_tree.length === 1) {
          if (only_tree[0].children !== undefined) {
            setTree(only_tree[0].children);
            // do not show progress if there is just one
            if (only_tree[0].children[0].children.length === 0) {
              setHideProgress(true);
            }
            renderQuestions(only_tree[0].children, "getData");
          }
        } else {
          only_tree = xmlVal.filter(
            (key: XMLNode) => key.attributes.cat === (internal_props.category_fallback ?? "shirt")
          );
          console.log("FALLBACK");
          console.log(internal_props.category_fallback);
          if (only_tree[0].children !== undefined) {
            setTree(only_tree[0].children);
            // do not show progress if there is just one
            if (only_tree[0].children[0].children.length === 0) {
              setHideProgress(true);
            }
            renderQuestions(only_tree[0].children, "getData");
          } else {
            setError(t("Cannot find questions for your product"));
          }
        }
      }
    });
  }

  function handleFreeFormChange(e: any) {
    setFreeForm(e.target.value);
  }

  function calculateMaxNumberOfQuestions(node: XMLNode | undefined, nodes: XMLNode[] = []) {
    let maxQuestions = 0;

    // Split into two parts doing the same code because at first we get an XMLNode[] but then we only get XMLNode
    if (nodes.length === 0) {
      // Stopping condition
      if (node?.children.length === 0) return 0;

      // Calculate the longest tree
      node?.children.forEach((childNode) => {
        const childMax = calculateMaxNumberOfQuestions(childNode);
        maxQuestions = childMax > maxQuestions ? childMax : maxQuestions;
      });
    } else {
      // Calculate the longest tree
      nodes.forEach((childNode) => {
        const childMax = calculateMaxNumberOfQuestions(childNode);
        maxQuestions = childMax > maxQuestions ? childMax : maxQuestions;
      });
    }

    // Increment the max value by one for the parent
    return maxQuestions + 1;
  }

  function renderQuestions(input: XMLNode[], what?: string) {
    // Calculate the max number of questions at this point of the tree
    const maxQuestions = calculateMaxNumberOfQuestions(undefined, input);
    setCurrentMaxQuestions(maxQuestions);
    setHelpExpand(false);
    console.log("RENDERING from " + what);
    console.log(input);
    // Set the questions
    setCurrentQuestions(
      input.map((tree_item) => ({
        text: tree_item.attributes.text,
        text_question:
          tree_item.attributes.text_question !== undefined
            ? tree_item.attributes.text_question.replace("{{shop}}", internal_props.shop)
            : undefined,
        image: tree_item.attributes.image,
        text_submit: tree_item.attributes.text_submit,
        text_detail: tree_item.attributes.text_detail,
        text_expand: tree_item.attributes.text_expand,
        id: tree_item.attributes.answer_id !== undefined ? tree_item.attributes.answer_id : tree_item.attributes.id,
        level_string: tree_item.attributes.level_string,
        required: !(tree_item.attributes.required === "false"),
        regex: tree_item.attributes.regex,
      }))
    );
  }

  /* What happens if a user clicks on an answer*/
  const answersQuestion = (answer: AnswerType, freeform: boolean = false) => {
    var answers_tmp = answers;

    answers_tmp.push(answer);
    setAnswers(answers_tmp);

    // get the tree of the underlying category
    var new_tree = tree.filter((key: XMLNode) => key.attributes.answer_id === answer.id);

    if (new_tree.length === 1) {
      if (new_tree[0].children !== undefined && new_tree[0].children.length > 0) {
        console.log("APPNEDING TREE");
        // append the tree to oldtrees
        var oldTree_tmp = oldTree;
        // Add the tree to oldTree
        oldTree_tmp.push(tree);
        setOldTree(oldTree_tmp);

        // For the new questions tree, set children
        setTree(new_tree[0].children);

        // render the questions

        renderQuestions(new_tree[0].children, "answerQuestion");
        setCurrentLevel(currentLevel + 1);
      } else {
        setCurrentLevel(currentLevel + 1);
        if (props.skipEnd) {
          setEnd(true);
          handleFinal();
        } else {
          setEnd(true);
        }
      }
    } else {
      // for freeform answers, there is no underlying tree at the end
      if (freeForm) {
        setCurrentLevel(currentLevel + 1);
        if (props.skipEnd) {
          setEnd(true);
          handleFinal();
        } else {
          setEnd(true);
        }
      }
    }
  };

  /* What happens if the user uses the BACK button*/
  function handleBack() {
    // in case there is a question tree
    if (oldTree.length > 0) {
      // go one level back
      setCurrentLevel(currentLevel - 1);

      // At the end, go back from end
      if (end) {
        setEnd(!end);
      } else {
        // get the oldTree and remove the last level that was
        // added
        var oldtree_tmp = oldTree.slice(0, oldTree.length - 1);
        setOldTree(oldtree_tmp);

        // Set the last level as tree
        setTree(oldTree[oldTree.length - 1]);
        renderQuestions(oldTree[oldTree.length - 1], "handleBack");
      }
      // take out last answer
      var answers_tmp = answers.slice(0, answers.length - 1);
      setAnswers(answers_tmp);
    } else {
      // NO question tree, means first level or END
      if (currentLevel > 0) {
        // In case a question was already answered but it was a first-level question
        if (end) {
          setEnd(!end);
        }

        // remove the answer
        var answers_tmpWithTree = answers.slice(0, answers.length - 1);
        setCurrentLevel(currentLevel - 1);
        setAnswers(answers_tmpWithTree);
      } else {
        // In case first screen, go to Welcome
        setCurrentLevel(currentLevel - 1);
        setAnswers([]);
      }
    }
  }

  // Start from the beginning
  function reset() {
    if (internal_props.lang !== undefined) {
      getData(internal_props.lang);
    } else {
      getData(i18n.language);
    }
    setSuccess(false);
    setEnd(false);
    setAnswers([]);
    setCurrentLevel(0);
    setDefaulthandled(false);
  }

  function handleFinal() {
    analytics.logEvent("return_questions_answered", {
      feature: "return questions",
      event: "User answered return questions",
    });

    // props.successHandler is required
    props.successHandler(answers);
    reset();
  }

  function handleSubmit(e: any, required: boolean, regex_input: string | undefined) {
    e.preventDefault();
    const submit_final = () => {
      setFreeFormError(undefined);
      const store_freeform = freeForm;
      setFreeForm("");
      answersQuestion(
        {
          text: store_freeform! ?? " ",
          id: e.target.id,
          question: currentQuestions[0].text_question !== undefined ? currentQuestions[0].text_question : "",
        },
        true
      );
    };
    if (freeForm !== "" || !required) {
      if (regex_input === undefined) {
        submit_final();
      } else {
        if (freeForm.match(new RegExp(regex_input ?? ".*")) !== null) {
          submit_final();
        } else {
          setFreeFormError(t("Please provide the right format"));
        }
      }
    } else {
      setFreeFormError(t("Please provide an answer"));
    }
  }

  function setRating() {
    setRatingValue(1);
  }
  function resetRating() {
    setRatingValue(undefined);
  }

  useEffect(() => {
    const skip_start = props.skipStart !== undefined && props.skipStart === true;

    if (tree.length === 0) {
      if (internal_props.lang === undefined) {
        getData(i18n.language);
        if (currentLevel === 0 && skip_start) {
          setCurrentLevel(1);
        }
      } else {
        i18n.changeLanguage(internal_props.lang);
        localStorage.setItem("lang", internal_props.lang);
        getData(internal_props.lang);
        if (currentLevel === 0 && skip_start) {
          setCurrentLevel(1);
        }
      }
    }

    // In case this is initialized with some answers
    // use the answersQuestion function to run
    // over all answers
    if (
      props.default_answers !== undefined &&
      props.default_answers.length > 0 &&
      !default_handled &&
      tree.length > 0
    ) {
      setTimeout(() => {
        setCurrentLevel(1);
        const ligth_answers = props.default_answers ?? [];
        for (let i = 0; i < ligth_answers.length - 1; i++) {
          answersQuestion(ligth_answers[i]);
        }
      }, 100);
      setDefaulthandled(true);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [i18n, internal_props.lang, props.skipStart, props.default_answers, tree]);

  if (props.userID === undefined || getToken() !== process.env.REACT_APP_RETURN_QUESTIONS_KEY) {
    return null;
  }

  return (
    <Container maxWidth="sm" mt={2}>
      {error !== "" ? (
        <Alert mb={4} mt={4} severity="error">
          {error}
        </Alert>
      ) : null}
      {success ? (
        // Thank you screen
        <div className={classes.main}>
          <img src={KeepoalaBanner} alt="Keepoala Logo" className={classes.logo} />
          <LatoText fontSize="xl" mb={1}>
            {t("Thank you")}
          </LatoText>
          <LatoText fontSize="l" mb={1}>
            {t("Your feedback will help to improve products and shop quality")}
          </LatoText>
          <Link href="/home">
            <Button>
              <ArrowBackIconBack />
              {t("back to home")}
            </Button>
          </Link>
        </div>
      ) : !end ? (
        <>
          {/* Questions Progress Bar */}
          {!hide_progress ? (
            <div style={{ marginBottom: "20px" }}>
              <ProgressQuestionsLinear
                variant="determinate"
                value={currentLevel === 0 ? 0 : ((currentLevel - 1) / (currentMaxQuestions + currentLevel - 1)) * 100}
              />
            </div>
          ) : null}
          {/* Questions */}
          {currentLevel === 0 ? ( // Welcome Screen
            <div className={classes.main}>
              <LatoText fontSize="xl" mb={1}>
                {internal_props.category !== "phyneproduct" ? t("Return Questions") : t("Questions about your order")}
              </LatoText>
              <LatoText fontSize="m" mb={3} align="left">
                {t("Here we will ask you some questions about your order")}. {t("Order Name")}:{" "}
                <b>{internal_props.orderName}</b>
                <br />
                <br />
                {internal_props.productName !== "" ? t("With the product") + ": " : null}
                <b>{internal_props.productName}</b>
                {internal_props.productName !== "" ? <br /> : null}
                <br />
                <br />
                {t(
                  "Please answer the questions carefully as they help to improve the product quality, lower returns and such help the environment"
                )}
                .
              </LatoText>
              <Button
                color="primary"
                onClick={() => {
                  setCurrentLevel(1);
                }}>
                {t("Start the questionaire")}
              </Button>
            </div>
          ) : (
            // Questions iterator
            <div className={classes.questions}>
              {/* old code on showing what we ask about */}
              {/* {internal_props.productName !== '' ? (
                <LatoText align='left' fontSize='m' mb={4}>
                  {t('We are asking you about')}:&nbsp;
                  <b>{internal_props.productName}</b>
                  {internal_props.shop !== undefined
                    ? ' ' + t('ordered at') + ' '
                    : null}
                  {internal_props.shop !== undefined ? (
                    <b>{internal_props.shop}</b>
                  ) : null}
                </LatoText>
              ) : null} */}

              {currentQuestions.length === 0 ? (
                <div></div>
              ) : currentQuestions[0].text_question === undefined ? (
                <div>
                  <InterText align="center" size="l" mb={4} weight={600}>
                    {currentQuestions[0].level_string !== undefined
                      ? currentQuestions[0].level_string
                      : levelstrings[currentLevel - 1]}
                  </InterText>
                </div>
              ) : (
                <div>
                  <InterText align="left" size="l" mb={4} weight={600}>
                    {currentQuestions[0].text_question}
                  </InterText>
                  {currentQuestions[0].image !== undefined ? (
                    <Img src={currentQuestions[0].image ?? null} alt="Question image"></Img>
                  ) : null}
                  {currentQuestions[0].text_detail !== undefined ? (
                    <div
                      onClick={
                        currentQuestions[0].text_expand !== undefined
                          ? () => setHelpExpand(true)
                          : () => {
                              console.log("undefined1");
                            }
                      }>
                      <InterText align="center" size="l" mb={4}>
                        {currentQuestions[0].text_detail.replace("&#38;", "&")}
                        {currentQuestions[0].text_expand !== undefined ? (
                          <InterText 
                            align="center" 
                            size="l" 
                            color={props.shop === "happybrush-store.myshopify.com" ? "happybrush" : "bright"}
                          >
                            {" " + t("Click here")}
                          </InterText>
                        ) : null}
                        {helpExpand ? <span>{currentQuestions[0].text_expand}</span> : null}
                        <br />
                        {}
                      </InterText>
                    </div>
                  ) : null}
                </div>
              )}

              {currentQuestions.map((question) => {
                if (question.text !== "Freeform") {
                  if (question.text === "Rating") {
                    return (
                      <FlexCenter>
                        {ratingValue === undefined ? (
                          <StyledRating
                            name={"rating" + question.id}
                            max={11}
                            onChange={(value) => {
                              answersQuestion({
                                text: ((value == null ? 0 : value) - 1).toString(),
                                id: question.id,
                                question: question.text_question,
                              });
                              setRating();
                              setTimeout(() => {
                                resetRating();
                              }, 300);
                            }}
                          />
                        ) : null}
                        <br />
                        <br />
                      </FlexCenter>
                    );
                  } else if (question.text === "Upload") {
                    return (
                      <FlexCenter>
                        <UploadForm
                          answerQuestion={(urls) => {
                            console.log(urls);
                            if (Array.isArray(urls)) {
                              answersQuestion({
                                text: urls.join(","),
                                id: question.id,
                                question: question.text_question,
                              });
                            } else {
                              answersQuestion({
                                text: urls.urls.join(","),
                                id: question.id,
                                question: question.text_question,
                              });
                            }
                          }}
                          text={question.text_question}
                          details={question.text_detail}
                          shop={props.shopId ?? "empty"}
                          id={(props.orderID ?? "1").toString()}
                        />
                        <br />
                        <br />
                      </FlexCenter>
                    );
                  } else {
                    if (!isOnfocus) {
                      return (
                        <Button
                          mb={2}
                          variant="contained"
                          key={question.id}
                          color="default"
                          onClick={() => {
                            // In case text_question is set, please tell it to the user at the end by adding it as a question
                            if (currentQuestions[0].text_question !== undefined) {
                              answersQuestion({
                                text: question.text,
                                id: question.id,
                                question: currentQuestions[0].text_question,
                              });
                            } else {
                              answersQuestion({
                                text: question.text,
                                id: question.id,
                                question: "",
                              });
                            }
                          }}
                          fullWidth>
                          {question.text}
                        </Button>
                      );
                    } else return null;
                  }
                } else {
                  return (
                    <form
                      key={question.id}
                      id={question.id}
                      className={classes.root}
                      onSubmit={(e) => handleSubmit(e, question.required === true ? true : false, question.regex)}>
                      <FormControl margin="normal" required={question.required === true ? true : false} fullWidth>
                        <Input
                          id={question.id}
                          name="freeform"
                          value={freeForm}
                          autoFocus={currentQuestions.length === 1 ? true : false}
                          label={question.text_question === undefined ? t("or tell us why") : ""}
                          onChange={handleFreeFormChange}
                          onFocus={() => {
                            //If is mobile we'll set the onfocus true
                            //Hide the questions button and hide backbutton
                            if (isMobile) {
                              setIsOnfocus(true);
                            }
                          }}
                          onBlur={() => {
                            //onfocusout
                            setIsOnfocus(false);
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth={currentQuestions.length === 1 ? true : undefined}>
                        <Button
                          mb={3}
                          color="default"
                          id="submit"
                          type="submit"
                          value="Submit"
                          variant="contained"
                          onClick={() => {
                            setIsOnfocus(false);
                          }}>
                          {currentQuestions.length === 1 && currentQuestions[0].text_submit !== undefined
                            ? currentQuestions[0].text_submit
                            : t('use the reason "why"')}
                        </Button>
                      </FormControl>
                      {freeFormError !== undefined ? (
                        <LatoText className={classes.error}>{freeFormError}</LatoText>
                      ) : null}
                    </form>
                  );
                }
              })}

              {
                //Here we'll add a condition on when a freeform is on focus

                // In case a start screen is skipped, allow the
                // component to have a back function from outside
                // this can basically do anything
                !isOnfocus ? (
                  props.skipStart && currentLevel === 1 ? (
                    <Button onClick={props.startBackHandler}>
                      <ArrowBackIconBack />
                    </Button>
                  ) : (
                    <Button onClick={handleBack}>
                      <ArrowBackIconBack />
                    </Button>
                  )
                ) : null
              }
            </div>
          )}
        </>
      ) : // Finishing YES/NO Screen
      props.skipEnd ? (
        <div className={classes.main}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.main}>
          {internal_props.productName !== "" ? (
            <LatoText>
              {t("We asked you about the product")}:&nbsp;
              <b>{internal_props.productName}</b>
            </LatoText>
          ) : null}
          <LatoText fontSize="l">{t("You answered")}</LatoText>
          <ul>
            {answers.map((an_answer: any, index) => {
              return (
                <Liindent key={"Final-Answers" + an_answer.id} indent={index}>
                  <LatoText fontSize="m">
                    {an_answer.question !== undefined ? an_answer.question + ": " : null}
                    <b>{an_answer.text}</b>
                  </LatoText>
                </Liindent>
              );
            })}
          </ul>
          <LatoText fontSize="l" align="center" mb={2}>
            {t("Is this correct")}?
          </LatoText>

          <div className={classes.twoButtons}>
            <Button onClick={handleBack}>
              <ArrowBackIconBack />
              {t("back")}
            </Button>
            <Button color="primary" onClick={handleFinal}>
              {props.nextExists !== undefined && props.nextExists ? t("Yes, next item") : t("Yes, send my answers")}
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}

ReturnQuestions.defaultProps = {
  category: "shirt",
  productName: "Das blaue T-Shirt",
  orderName: "#1",
};
