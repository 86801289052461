import React from "react";
import { SpacingProps } from "@material-ui/system";
import styled from "styled-components";
import Colors from "../utils/helper/Colors";
import { useTheme } from "@material-ui/core/styles";

type transformType = "uppercase" | "lowercase" | "none";
type sizeType = "m" | "l" | "xl" | "xxl" | "xxxl" | "s";
type ColorType =
  | "main"
  | "light"
  | "bright"
  | "black"
  | "white"
  | "grey"
  | "lightgrey"
  | "greengrey"
  | "transparent"
  | "happybrush"
  | undefined;
type InterTextPropsDefault = {
  size: sizeType;
  align: string;
  color: ColorType;
  transform: transformType;
  weight: number;
  children: any;
};

type InterTextProps = SpacingProps & InterTextPropsDefault;

export default function InterText(props: InterTextProps) {
  let fontSize;
  switch (props.size) {
    case "s":
      fontSize = "12px";
      break;
    case "m":
      fontSize = "14px";
      break;
    case "l":
      fontSize = "18px";
      break;
    case "xl":
      fontSize = "20px";
      break;
    case "xxl":
      fontSize = "36px";
      break;
    case "xxxl":
      fontSize = "48px";
      break;
    default:
      fontSize = "12px";
      break;
  }
  const theme = useTheme();
  const { align, color, transform, weight, children, ...spacingprops } = props;

  const ExportFinal = styled.div`
    font-family: ${(props) => props.theme.fontFamily ?? "Inter"};
    font-size: ${fontSize};
    text-align: ${align};
    color: ${(props) =>
      color === undefined
        ? props.theme.text ?? Colors.keepoala["black"]
        : props.theme[color] !== undefined
        ? props.theme[color]
        : Colors.keepoala[color]};
    text-transform: ${transform};
    &:hover {
      color: ${(props: any) => (props.link ? Colors.keepoala.main : "")};
    }
    &:link {
      color: ${color};
    }
    font-weight: ${weight};
    margin: ${spacingprops.m !== undefined ? theme.spacing(spacingprops.m) : ""}px;
    margin-top: ${spacingprops.mt !== undefined ? theme.spacing(spacingprops.mt) : ""}px;
    margin-bottom: ${spacingprops.mb !== undefined ? theme.spacing(spacingprops.mb) : ""}px;
    margin-left: ${spacingprops.ml !== undefined ? theme.spacing(spacingprops.ml) : ""}px;
    margin-right: ${spacingprops.mr !== undefined ? theme.spacing(spacingprops.mr) : ""}px;
  `;

  return <ExportFinal>{children}</ExportFinal>;
}

InterText.defaultProps = {
  transform: "none",
  color: undefined,
  align: "left",
  size: "m",
  weight: 400,
};
