import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface AddressFormProps {
  onSubmit: (data: AddressFormData) => void;
  value: AddressFormData;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

export interface AddressFormData {
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: 'DE' | 'AT';
}

const AddressForm: React.FC<AddressFormProps> = ({
  onSubmit,
  value,
  setIsValid,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<AddressFormData>(value);

  const [errors, setErrors] = useState({
    name: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: '',
  });

  const validate = (): boolean => {
    let isValid = true;
    const newErrors = {
      name: '',
      address1: '',
      address2: '',
      zip: '',
      city: '',
      country: '',
    };

    if (formData.name.trim() === '') {
      newErrors.name = t('Name is required');
      isValid = false;
    }

    if (!/^\S+ \S+/.test(formData.name.trim())) {
      newErrors.name =
        t('Name is not recognizable as name') +
        '. ' +
        t('Please enter firstname and lastname');
      isValid = false;
    }

    if (formData.address1 === '') {
      newErrors.address1 = t('Address 1 is required');
      isValid = false;
    } else {
      const house_number_match: RegExpMatchArray | null =
        formData.address1.match(/[1-9]\d*(?:[-\s]?[a-zA-Z0-9]+)?/);
      const house_number: string =
        house_number_match !== null ? house_number_match[0] : '';
      const street = formData.address1.replace(house_number, '').trim();
      if (street === '' || house_number === '') {
        newErrors.address1 = t('We cannot regognize street and house number');
        isValid = false;
      }
    }

    if (!/^\d{4,5}$/.test(formData.zip)) {
      newErrors.zip = t('Zip code must be 4 or 5 digits');
      isValid = false;
    }

    if (formData.city.trim() === '') {
      newErrors.city = t('City is required');
      isValid = false;
    }

    setErrors(newErrors);
    setIsValid(isValid);
    return isValid;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name as string]: value,
    }));
    setTimeout(() => {
      validate();
    }, 200);
  };

  useEffect(() => {
    onSubmit(formData);
  }, [formData, onSubmit]);

  useEffect(() => {
    setIsValid(validate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsValid]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label={t('Name')}
        name='name'
        value={formData.name}
        onChange={handleChange}
        error={Boolean(errors.name)}
        helperText={errors.name}
        required
      />
      <TextField
        label={t('Address1')}
        name='address1'
        value={formData.address1}
        onChange={handleChange}
        error={Boolean(errors.address1)}
        helperText={errors.address1}
        required
      />
      <TextField
        label={t('Address2')}
        name='address2'
        value={formData.address2}
        onChange={handleChange}
        error={Boolean(errors.address2)}
        helperText={errors.address2}
      />
      <TextField
        label={t('Zip')}
        name='zip'
        value={formData.zip}
        onChange={handleChange}
        error={Boolean(errors.zip)}
        helperText={errors.zip}
        required
      />
      <TextField
        label={t('City')}
        name='city'
        value={formData.city}
        onChange={handleChange}
        error={Boolean(errors.city)}
        helperText={errors.city}
        required
      />
      <FormControl required>
        <InputLabel id='country-select-id'>{t('Country')}</InputLabel>
        <Select
          labelId='country-select-id'
          name='country'
          value={formData.country}
          label={t('Country')}
          onChange={handleChange}
        >
          <MenuItem value='DE'>{t('Germany')}</MenuItem>
          <MenuItem value='AT'>{t('Austria')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AddressForm;
